<template>
  <div>
    <el-card header="登录" class="login">
      <div>
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on"
          label-position="left">
          <el-form-item prop="username" label="用户名">
            <el-input v-model="loginForm.username" name="username" type="text" auto-complete="on" placeholder="用户名" />
          </el-form-item>
          <el-form-item prop="password" label="密码">
            <el-input v-model="loginForm.password" type="password" name="password" auto-complete="on"
              placeholder="密码" />
          </el-form-item>
          <el-form-item>
            <el-button :loading="loading" type="primary" style="width:100%;" @click="handleLogin()">登录
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loading: false,
        loginForm: {
          username: null,
          password: null
        },
        loginRules: {
          username: [{
            required: true,
            trigger: "blur",
            message: "请输入用户名"
          }],
          password: [{
            required: true,
            trigger: "blur",
            message: "请输入密码"
          }]
        }
      };
    },
    methods: {
      handleLogin() {
        this.$refs.loginForm.validate(valid => {
          if (valid) {
            this.loading = true;
            this.$store
              .dispatch("Login", this.loginForm)
              .then(res => {
                this.loading = false;
                if (res.success) {
                  this.$router.push('/');
                }
              })
              .catch(err => {
                console.log(err);
                this.loading = false;
              });
          }
        });
      }
    }
  };
</script>


<style lang="scss" scoped>
  .login {
    margin: 15vh auto 0;
    width: 400px;
  }
</style>
